<template>
  <div>
    <!-- 其他页面内容 -->

    <!-- 版权底部 -->
    <footer class="footer">
      <div class="footer-content">
        <!-- 版权信息 -->
        <p>&copy; 2024 Hwad Games Inc. All rights reserved.</p>

        <!-- 隐私声明 -->
        <p>
          <a href="/privacy.html" target="_blank">Privacy Policy</a> | 
          <a href="/service.html" target="_blank">Terms of Service</a>
        </p>

        <!-- 联系方式 -->
        <p>
          <strong>Contact Us:</strong> <a href="mailto:hwad.suppert@gmail.com">hwad.suppert@gmail.com</a> | 
          <a href="tel:+1234567890"></a>
        </p>

        <!-- BD 邮件 -->
	<!--
        <p>
          <strong>Business Development:</strong> <a href="mailto:bd@hwad.games">bd@hwad.games</a>
        </p>
        --> 
        <!-- 公司链接 -->
        <p>
          Powered by <a href="https://hwad.games" target="_blank">Hwad Games Inc.</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer {
  margin-top:10px;
  background-color: #333;   /* 底部背景颜色 */
  color: #fff;              /* 文字颜色 */
  padding: 10px 0;          /* 上下内边距 */
  text-align: center;       /* 文字居中 */
  font-size: 14px;          /* 字体大小 */
  line-height: 1.8;         /* 行高，增加可读性 */
}

.footer a {
  color: #fff;              /* 链接文字颜色 */
  text-decoration: none;    /* 去掉下划线 */
}

.footer a:hover {
  text-decoration: underline; /* 悬停时添加下划线 */
}

.footer-content p {
  margin: 5px 0; /* 每个段落上下间距 */
}

/* 响应式设计 */
@media (max-width: 600px) {
  .footer {
    font-size: 12px; /* 手机屏幕上的字体大小 */
  }

  .footer-content p {
    font-size: 12px;
  }
}
</style>
