<template>
  <div class="navbar">
    <!-- 左侧 Logo -->
    <div class="logo ">
      <a href="/">
        <img id="logoimg" class="entered error" alt="logo" src="@/assets/home.png"/>
      </a>
    </div>

    <!-- 中间搜索框 -->
    <div class="search-box">
      <input
        type="text"
        placeholder="Search"
        v-model="searchQuery"
        @keyup.enter="search"
      />
      <i class="icon-search fa fa-search" @click="search"></i>
    </div>
<!-- Navigation Bar -->
    <ul class="nav-ul">
      <li class="nav_li">
        <a href="/" title="favorite">
          <img class="lazy" src="@/assets/history_ic.png" alt="" />
        </a>
      </li>
      <li class="nav_li" id="menu">
        <a href="./category"><img class="lazy" src="@/assets/classification_ic.png" alt="" /></a>
      </li>
    </ul>

  </div>

</template>

<script>
export default {
  data() {
    return {
      searchQuery: '', // 搜索框绑定值
    };
  },
  methods: {
    search() {
      if (this.searchQuery.trim()) {
          console.log('Searching for:', this.searchQuery);
	  // 在这里实现搜索逻辑
          // this.$router.push({ path: "/search", query: { q: this.searchQuery } });
	  window.location.href = "/search?q="+ this.searchQuery;
      } else {
        alert('Please enter the search content!');
      }
    },
  },
};
</script>

<style scoped>
@import '~font-awesome/css/font-awesome.min.css';
/* 顶部导航栏样式 */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo部分 */
.logo a{display:inline-block;height:100%;  margin-right: 10px;}
.logo img {
    max-height: 100%;
    width: 24px;
    height: 24px;
    margin-top:5%;
}

.logo span {
  font-size: 18px;
  font-weight: bold;
  color: #ff6f00;
}

/* 搜索框样式 */
.search-box {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 10px;
  width: 100%;
}

.search-box input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding-left: 5px;
}

.search-box .icon-search {
  font-size: 16px;
  color: #999;
  cursor: pointer;
}



.nav-ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav_li {
  margin-left: 15px;
}

.nav_li a img {
  width: 24px;
  height: 24px;
}

.adsense {
  overflow: hidden;
}
</style>
