<template>
  <TopSearch/>
  <div class="search-result">
    <h3>Search Results for: "{{ keyword }}"</h3>
           <div class="game-list">
	    <div class="game-row" v-for="(row, rowIndex) in filteredGames" :key="rowIndex">
	      <div class="game-item" v-for="(game, index) in row" :key="index"  @click="startGame(game)">
		<img :src="game.image" alt="game image" class="game-image" />
		<h2>{{ game.name }}</h2>
	      </div>
	    </div>
	  </div>
  </div>
  <BottomPage/>
</template>

<script>
import games from "@/data/games.js";
import TopSearch from '@/components/TopSearch.vue';
import BottomPage from '@/components/BottomPage.vue';

export default {
 components: {
    TopSearch,
    BottomPage
  },
  name: "SearchResult",
  data() {
    return {
      keyword: this.$route.query.q || "", // 从 URL 获取搜索关键字
      games: games,
    };
  },
  computed: {
    filteredGames() {
      // 根据关键字搜索游戏名称
      let fgames = this.games.filter((game) =>
        game.name.toLowerCase().includes(this.keyword.toLowerCase())
      );

      const chunkSize = 3;
      let result = [];
      for (let i = 0; i < fgames.length; i += chunkSize) {
        result.push(fgames.slice(i, i + chunkSize));
      }
      return result;
    },
  },
  methods: {
    openGame(game) {
      // 在当前页面打开游戏
      window.location.href = game.url;
    },
  },
};
</script>

<style scoped>
.search-result {
  background-color: #f0f0f0;  /* 灰色背景 */
  padding-top: 5px;
  text-align: center;
}

.game-list {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.game-row {
  width:100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap; /* 允许换行，防止内容超出 */
  overflow: hidden; /* 隐藏超出部分 */
  box-sizing: border-box; /* 确保 padding 和边框不会增加宽度 */
}

.game-item {
  text-align: center;
  width: 30%;
  max-width: calc(30% - 5px); /* 限制最大宽度 */
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 10px;
}

.game-item h2 {
  width: 100%;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;    /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 如果文本过长，显示省略号 */
  margin:0px;
}

.game-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: contain; /* 确保图片在容器内完整显示 */
}
</style>
