<template>
   <div class="app"><router-view /></div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
.app {
  max-width: 1024px;  /* ������Ϊ 1024px */
  margin: 0 auto;     /* ˮƽ���� */
  padding: 0 0px;    /* ��ѡ�������Ҽ����ʵ��ļ�� */
  box-sizing: border-box; /* �����ڱ߾�ͱ߿� */
}
</style>