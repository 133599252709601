<template>
  <div id="app">
    <h2>Hwad Games</h2>
    <TopSearch/>
    <!-- 显示每行一个游戏的组件 -->
    <SingleGameList :games="gamesForSingle" />

    <hr /> <!-- 分隔符，区分两个组件 -->

    <!-- 显示每行三个游戏的组件 -->
    <ThreeGameList :games="gamesForThree" />
    <BottomPage/>
  </div>
</template>

<script>
// 导入子组件
import TopSearch from '@/components/TopSearch.vue';
import SingleGameList from '@/components/SingleGameList.vue';
import ThreeGameList from '@/components/ThreeGameList.vue';
import BottomPage from '@/components/BottomPage.vue';
//import gamesData from '@/data/games';  // 假设从一个 games.js 文件中获取数据
import gamesData from '@/data/gamesData.json';


export default {
  components: {
    TopSearch,
    SingleGameList,
    ThreeGameList,
    BottomPage,
  },
  data() {
    return {
      // 假设从 gamesData 中导入所有游戏数据
      games: gamesData,  // 所有游戏数据
    };
  },
  computed: {
    // 计算属性用于为两个组件提供不同的数据
    gamesForSingle() {
      // 可以随机选择一定数量的游戏
      return this.getRandomGames(3);  // 只取前 6 个或随即选择
    },
    gamesForThree() {
      // 同样可以选择不同的游戏集合
      return this.getRandomGames(9);  // 只取前 6 个或随即选择
    },
  },
  methods: {
    // 随机从所有游戏中选取若干个
    getRandomGames(count) {
      const shuffledGames = this.shuffleArray([...this.games]); // 打乱顺序
      return shuffledGames.slice(0, count);  // 取前 count 个游戏
    },
    // 洗牌算法
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // 交换元素
      }
      return array;
    },
    
  }
};
</script>

<style>
h2 {
  text-align: center;
  margin-bottom: 15px;
}
</style>
