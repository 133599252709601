<template>
  <div class="game-list">
    <div class="game-row" v-for="(row, rowIndex) in chunkedGames" :key="rowIndex">
      <div class="game-item" v-for="(game, index) in row" :key="index"  @click="startGame(game)">
        <img :src="game.images[0]" alt="game image" class="game-image" />
        <h2>{{ game.name }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    games: Array, // 接收父组件传递的游戏数据
  },
  computed: {
    chunkedGames() {
      const chunkSize = 3;
      let result = [];
      for (let i = 0; i < this.games.length; i += chunkSize) {
        result.push(this.games.slice(i, i + chunkSize));
      }
      return result;
    },
  },
  methods: {
    startGame(game) {
      window.location.href = game.url;
    },
  },
};
</script>

<style scoped>
.game-list {
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.game-row {
  width:100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap; /* 允许换行，防止内容超出 */
  overflow: hidden; /* 隐藏超出部分 */
  box-sizing: border-box; /* 确保 padding 和边框不会增加宽度 */
}

.game-item {
  text-align: center;
  width: 30%;
  max-width: calc(30% - 5px); /* 限制最大宽度 */
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 10px;
}

.game-item h2 {
  width: 100%;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;    /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 如果文本过长，显示省略号 */
  margin:0px;
}

.game-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: contain; /* 确保图片在容器内完整显示 */
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
