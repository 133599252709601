import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage.vue";
import SearchResult from "@/components/SearchResult.vue";
import CategoryDetail from "@/components/CategoryDetail.vue";
import CategoryList from "@/components/CategoryList.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/search",
    name: "SearchResult",
    component: SearchResult,
  },
  {
    path: "/category",
    name: "CategoryList",
    component: CategoryList,
  },
  {
    path: "/categoryDetail",
    name: "CategoryDetail",
    component: CategoryDetail,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
