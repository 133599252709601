export default [{
	image: "http://img.cdn.famobi.com/portal/html5games/images/tmp/180/UfoRunTeaser.jpg?v=0.1.32-6d45f1f6",
	name: "Ufo Run",
	description: "",
	category: "Best",
	id: 1,
	url: "http://play.famobi.com/ufo-run"
}, {
	image: "http://img.cdn.famobi.com/portal/html5games/images/tmp/180/FitItQuickTeaser.jpg?v=0.1.32-6d45f1f6",
	name: "FitItQuickTeaser",
	description: "",
	category: "Match 3",
	id: 2,
	url: "http://play.famobi.com/fit-it-quick"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/BubbleWoodsTeaser.jpg?v=0.2-f2e20ae1",
	name: "Bubble Shooter",
	description: "",
	category: "Bubble Shooter",
	id: 3,
	url: "http://play.famobi.com/jelly-collapse"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/ColorWaterSort3dTeaser.jpg?v=0.2-f2e20ae1",
	name: "Puzzle",
	description: "",
	category: "Puzzle",
	id: 4,
	url: "http://play.famobi.com/mini-putt-garden"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/SweetHangmanTeaser.jpg?v=0.2-f2e20ae1",
	name: "Quiz",
	description: "",
	category: "Quiz",
	id: 5,
	url: "http://play.famobi.com/2048"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/GinRummyPlusTeaser.jpg?v=0.2-f2e20ae1",
	name: "Cards",
	description: "",
	category: "Cards",
	id: 6,
	url: "http://play.famobi.com/connect-me-factory"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/3d_AnimeFantasyTeaser.jpg?v=0.2-f2e20ae1",
	name: "Girls",
	description: "",
	category: "Girls",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/NutRush3ChristmasTeaser.jpg?v=0.2-f2e20ae1",
	name: "Jump-Run",
	description: "",
	category: "Jump & Run",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/NeonRiderTeaser.jpg?v=0.2-f2e20ae1",
	name: "AllGames",
	description: "",
	category: "Arcade",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/HighwayRiderExtremeTeaser.jpg?v=0.2-f2e20ae1",
	name: "AllGames",
	description: "",
	category: "Racing",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/BlazeKickTeaser.jpg?v=0.2-f2e20ae1",
	name: "AllGames",
	description: "",
	category: "Sport",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.cdn.famobi.com/portal/html5games/images/tmp/DeflyTeaser.jpg?v=0.2-f2e20ae1",
	name: "AllGames",
	description: "",
	category: "Multiplayer",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.gamescdn.top/Jewels-Blitz-4.jpg",
	name: "AllGames",
	description: "",
	category: "Casual",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.gamescdn.top/Shoot-Bubble-Extreme.jpg",
	name: "AllGames",
	description: "",
	category: "Shooting",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.gamescdn.top/water_flow.jpg",
	name: "AllGames",
	description: "",
	category: "HyperCasual",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}, {
	image: "https://img.gamescdn.top/Coin_Pusher_Monster.png",
	name: "AllGames",
	description: "",
	category: "Action",
	id: 7,
	url: "http://play.famobi.com/fishy-rush"
}]