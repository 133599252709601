<template>
  <TopSearch/>
  <div class="game-category-list">
    <div class="game-category" v-for="category in chunkedCategories" :key="category.id">
      <div class="category-item" v-for="(game, index) in category" :key="index"  @click="gotoCategoryDetail(game)">
        <img :src="game.image" :alt="game.category" class="category-icon" />
        <span>{{ game.category }}</span>
      </div>
    </div>
  </div>
  <BottomPage/>
</template>

<script>
import categoriesData from "@/data/category.js"; // 引入游戏类别数据
import TopSearch from '@/components/TopSearch.vue';
import BottomPage from '@/components/BottomPage.vue';


export default {
  components: {
    TopSearch,
    BottomPage,
  },
  data() {
    return {
      categories: categoriesData, // 游戏数据
    };
  },
  computed: {
    // 按照category进行分组，每组最多2个项目
    chunkedCategories() {
      const grouped = [];
      let temp = [];
      // 根据category属性进行分组，每次放2个游戏
      this.categories.forEach((game, index) => {
        if (temp.length < 2) {
          temp.push(game);
        }
        if (temp.length === 2 || index === this.categories.length - 1) {
          grouped.push(temp);
          temp = [];
        }
      });
      return grouped;
    }
  },
  methods: {
    gotoCategoryDetail(game) {
      this.$router.push({ path: "/categoryDetail", query: { q: game.category } });
    },
  },
};
</script>

<style scoped>
/* 整体背景为灰色 */
.game-category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* 每个项目之间的间距 */
  justify-content: space-between; /* 让项目分布更均匀 */
  background-color: #f0f0f0; /* 灰色背景 */
  padding: 10px; /* 给容器添加内边距 */
}

/* 每个游戏类别项的样式 */
.game-category {
  display: flex;
  flex-direction: row;  /* 每行显示两个项目 */
  justify-content: space-between; /* 保证每行显示两个 */
  width: 100%; /* 确保每行占满宽度 */
  gap: 10px;
  overflow: hidden; /* 隐藏超出部分 */
  box-sizing: border-box; /* 确保 padding 和边框不会增加宽度 */
}

/* 每个游戏项目的样式 */
.category-item {
  display: flex;
  align-items: center; /* 垂直方向居中对齐图片和文字 */
  width: 48%;  /* 每行显示2个项目 */
  max-width: calc(47% - 5px); /* 限制最大宽度 */
  text-align: center;
  background-color: white; /* 白色背景 */
  padding: 5px;
  border-radius: 8px; /* 圆角效果 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}

/* 图标样式 */
.category-icon {
  width: 48px;
  height: 48px;
  margin-right: 10px; /* 图标和文字之间的间距 */
  border-radius: 5px;
}

/* 保证文字的样式 */
.game-category span {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;    /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 如果文本过长，显示省略号 */
}
</style>
