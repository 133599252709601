<template>
  <div class="game-list">
    <div class="game-item" v-for="(game, index) in games" :key="index"  @click="startGame(game)">
      <img :src="game.images[0]" alt="game image" class="game-image" />
      <h2>{{ game.name }}</h2>
      <!--
      <p>{{ game.description }}</p>
      <button @click="startGame(game)">Play</button>
      -->
    </div>
    <div class="game-item" style="max-height:300px;width:100%">
     <AdNtBig01 />
    </div>
  </div>
</template>

<script>

import AdNtBig01 from '@/components/AdNtBig01.vue';

export default {
 components: {
    AdNtBig01,
  },
  props: {
    games: Array, // 接收父组件传递的游戏数据
  },
  methods: {
    startGame(game) {
      window.location.href = game.url;
    },
  },
};
</script>

<style scoped>
.game-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:10px;
  gap: 5px;
}

.game-item {
  text-align: center;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
}

.game-item h2 {
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;    /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 如果文本过长，显示省略号 */
  margin:0px;
}

.game-image {
  width: 100%;
  height:50%;
  max-height:300px;
  border-radius: 5px;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
