<template>
  <div ref="adsenseContainer" class="adsense-container"></div>
</template>

<script>
export default {
  name: 'AdNative',
  mounted() {
    // 动态加载广告脚本
    this.loadAdScript();
  },
  methods: {
    loadAdScript() {
      // 1. 创建 <script> 标签并加载广告脚本
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4414222009909293';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.onload = this.insertAd;  // 加载完成后插入广告
      document.head.appendChild(script);
    },
    insertAd() {
      // 2. 创建 <ins> 标签，设置广告的属性
      const ad = document.createElement('ins');
      ad.className = 'adsbygoogle';
      ad.style = 'display:block';
      ad.setAttribute('data-ad-client', 'ca-pub-4414222009909293');
      ad.setAttribute('data-ad-slot', '5448146721');
      ad.setAttribute('data-ad-format', 'fluid');
      ad.setAttribute('data-ad-layout-key', '+1v+rs+3d-4r-3z');

      // 将广告插入到容器中
      this.$refs.adsenseContainer.appendChild(ad);

      // 推送广告请求
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
}
</script>

<style scoped>
.adsense-container {
  text-align: center;
  overflow: hidden;
  margin: 5px 0;
}
</style>
